<template>
	<section v-if="reviews.length" class="main-content review-section background-light">
		<div class="row">
			<div class="columns column12 align-center">
				<h2 class="subtitle">Reviews</h2>
				<h2>{{ header }}</h2>
			</div>
		</div>
		<div class="row">
			<div class="columns column12 review-slider">
				<client-only>
					<carousel :per-page="1" :pagination-position="'bottom-overlay'" class="carousel">
						<slide v-for="review in reviews" :key="review.ID" class="review-slider-content">
							<p>{{ review.content }}</p>
							<div class="border" />
							<div class="source">
								<span>{{ review.firstName }}</span>
								<strong v-if="review.date">
									{{ $d(new Date(review.date.date.split(' ')[0]), 'short') }}
								</strong>
								<span class="source-logo" :class="review.source" />
							</div>
						</slide>

						<template #addons>
							<pagination />
						</template>
					</carousel>
				</client-only>
			</div>
		</div>
	</section>
</template>

<script setup>
const { locale } = useI18n();
const config = useRuntimeConfig();

defineProps({
	header: { type: String, default: '' },
});

const { data: reviews } = await useWebsiteFetch('reviews', {
	query: { language: locale.value },
	key: `${locale.value}/reviews`,
});

const getReviewTotalAverage = () => {
	const sum = reviews.value
		.filter((review) => review.rating)
		.reduce((total, current) => total + parseInt(current.rating, 10), 0);

	return sum / reviews.value.length;
};

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'AggregateRating',
				'@id': `${config.public.siteUrl}#aggregaterating`,
				bestRating: 10,
				ratingValue: getReviewTotalAverage(),
				ratingCount: reviews.value.length,
				itemReviewed: {
					'@id': `${config.public.siteUrl}#hotel`,
				},
			}),
		},
	],
});
</script>

<style lang="scss" scoped>
.review-slider {
	padding: 25px 250px 50px;
	overflow: hidden;
	font-size: calc(var(--body-font-size) + 2px);

	&::before,
	&::after {
		content: '';
		display: inline-block;
		width: 159px;
		height: 139px;
		background: url('~/assets/images/reviews-before.png');
		background-size: 159px;
		position: absolute;
		top: 10px;
		left: 40px;
	}

	&::after {
		background: url('~/assets/images/reviews-after.png');
		background-size: 159px;
		inset: auto 60px 30px auto;
	}

	.review-slider-content {
		flex-direction: column;
		text-align: center;

		.border {
			height: 1px;
			width: 100%;
			background-color: #efefef;
			margin: 10px 0 20px;
		}

		.source {
			width: 100%;
			display: flex;
			flex-flow: column wrap;
			justify-content: center;
			align-items: center;
			margin: 10px 0 40px;
			font-size: calc(var(--body-font-size) - 1px);

			span,
			strong {
				margin: 0 4px 0 0;
			}

			.source-logo {
				margin: 0 auto;
				width: 100px;
				height: 67px;
				background-size: 100px 67px;

				&.tripadvisor {
					background-image: url('~/assets/images/review-logo-tripadvisor.png');
				}

				&.bookingcom {
					background-image: url('~/assets/images/review-logo-bookingcom.png');
				}

				&.zoover {
					background-image: url('~/assets/images/review-logo-zoover.png');
				}

				&.expedia {
					background-image: url('~/assets/images/review-logo-expedia.png');
				}

				&.google {
					background-image: url('~/assets/images/review-logo-google.png');
				}
			}
		}
	}
}

/* stylelint-disable selector-class-pattern */
:deep(.carousel__viewport ol),
:deep(.carousel__pagination) {
	margin: 0;
}

:deep(.carousel__pagination-item) {
	margin: 0 4px;

	button {
		width: 16px;
		height: 16px;
		background: var(--subtitle-color);

		&.carousel__pagination-button--active {
			background: var(--primary-color);
		}

		&::after {
			display: none;
		}
	}
}
/* stylelint-enable selector-class-pattern */

@media (max-width: 900px) {
	.review-slider {
		padding: 50px 90px;
		overflow: hidden;

		&::before,
		&::after {
			width: 80px;
			height: 70px;
			background: url('~/assets/images/reviews-before.png');
			background-size: 80px;
			top: 10px;
			left: 20px;
		}

		&::after {
			background: url('~/assets/images/reviews-after.png');
			background-size: 80px;
			inset: auto 20px 10px auto;
		}
	}
}

@media (max-width: 768px) {
	.review-slider {
		padding: 50px 70px;
		overflow: hidden;
	}
}

@media (max-width: 600px) {
	.review-slider {
		padding: 20px;
	}
}
</style>
